import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Accordion from 'react-bootstrap/Accordion';


const Text = () => (

  <Container fluid>
        <Col style={{ margin: "0 auto", maxWidth: "610px", minHeight: "100vh" }}>
            <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <Col sm style={{ display: "flex", flexDirection: "row", alignItems: "center", columnGap: "16px" }}>
                <Image src="/images/pfp.png" roundedCircle fluid style={{ height: "92px", width: "92px", marginLeft: "1rem", marginRight: "1rem" }}/>
                <Row style={{ display: "grid" }}>
                  <p style={{ marginBottom: "0px" }}>Freesoul Kofi</p>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <p style={{ textAlign: "justify", textJustify: "inter-character", fontWeight: "lighter" }}>
                How might we harmonize human productivity with the natural processes of the Earth? 
                I’m a design-engineer and researcher studying systems. 
                I'm interested in developing and implementing AI copilots 
                for the ecological engineering of socio-technical systems.
                </p>
              </Col>
            </Row>
            <hr style={{ }} />
            <Row>
              <a href="https://work.freesoul.dev"><h5>work</h5></a>
              {/* <a href="https://art.freesoul.dev"><h5>/art</h5></a> */}
              <a href="https://stream.freesoul.dev"><h5>livestream</h5></a>
            </Row>
            <hr />
            <Row>
              <a href="https://read.cv/freesoul"><h5>read.cv</h5></a>
              {/* <a href="https://github.com/freesoul-dev"><h5>github</h5></a> */}
              <a href="https://www.are.na/freesoul"><h5>are.na</h5></a>
            </Row>
            {/* <hr /> */}
            <Row>
              {/* <a href="https://ontologee.bigcartel.com"><h5>ontologee</h5></a> */}
              {/* <a href="https://www.instagram.com/earwax.fm/"><h5>earwax.fm</h5></a> <code> media services</code> */}
            </Row>
            <Row>
              {/* <a href="https://soundcloud.com/freesoul"><h5>soundcloud</h5></a> */}
              <a href="https://youtube.com/@freesoul-dev"><h5>youtube</h5></a>
              {/* <a href="https://vimeo.com/freesouldev"><h5>vimeo</h5></a> */}
              {/* <a href="https://twitch.tv/freesouldev"><h5>twitch</h5></a> */}
              {/* <a href="https://instagram.com/freesoul.dev"><h5>instagram</h5></a> */}
            </Row>
            <hr />
            <code> 
              {/* Side quests: For multimedia services dm me <a href="https://instagram.com/earwax.fm"><code>@earwax.fm.</code></a>  */}
              If you're looking for sound baths, schedule an intake <a href="https://calendly.com/freesoul-dev/intake"><code>here.</code></a>
            </code>
            <footer style={{ }}><code>Copyright © 2025 Freesoul Kofi El Shabazz-Thompson, All Rights Reserved.</code></footer>
        </Col>
  </Container>

);

export default Text;
